
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import ProductionPeelings, {
  exportProductionPeelingData,
  exportPDFProductionPeelings,
} from "@/core/data/productionPeeling";
import {
  ProductionPeeling,
  getProductionPeelings,
  deleteProductionPeeling,
} from "@/core/data/productionPeeling";
import { useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";
import { PERMISSION_MODULE_NAME } from "@/core/data/permission";

export default defineComponent({
  name: "ProductionPeeling-listing",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const checkedProductionPeeling = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    const selectedMaterial = computed(() => {
      return route.params.designMaterialId;
    });
    const selectedDesign = computed(() => {
      return route.params.designId;
    });
    const selectedProduciton = computed(() => {
      return route.params.id;
    });
    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllProductionPeeling = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<ProductionPeeling>>(ProductionPeelings);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);
    const getListItems = function (pageNumber) {
      console.log("selectedMaterial.value", selectedMaterial.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getProductionPeelings(
          currentPage.value,
          searchData.value,
          selectedStatus.value,
          selectedProduciton.value.toString(),
          selectedMaterial.value.toString()
        ).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          console.log("tableData", tableData.value);
          let promiseList: any = [];
          tableData.value.forEach((item) => {
            if (userIdList.value.indexOf(item.created_by) == -1) {
              userIdList.value.push(item.created_by);
              promiseList.push(getUserName(item.created_by));
            }
            Promise.all(promiseList).then(() => {
              res(resObj);
            });
          });
          console.log("tableData", tableData.value);
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id]) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("ProductionPeelings Listing", [
        "Apps",
        "ProductionPeelings",
      ]);
      getListItems(1);
    });

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedProductionPeeling.value.forEach((item) => {
        deleteProductionPeeling(item);
      });
      checkedProductionPeeling.value.length = 0;
    };
    const exportFewSites = () => {
      exportProductionPeelingData(
        selectAllProductionPeeling.value
          ? "all"
          : checkedProductionPeeling.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your ProductionPeeling has been exported.",
          "success"
        );
      });
    };
    const exportPDFProductionPeelingsHanlder = () => {
      exportPDFProductionPeelings(
        selectAllProductionPeeling.value
          ? "all"
          : checkedProductionPeeling.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your ProductionPeeling has been exported.",
          "success"
        );
      });
    };
    const deleteProductionPeelingItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteProductionPeeling(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your ProductionPeeling has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addProductionPeeling = (item) => {
      if (item.id) {
        router.push({
          name: "apps-peeling-production-details",
          params: {
            id: route.params.id,
            designMaterialId: selectedMaterial.value,
            peelingId: item.id,
          },
        });
      } else {
        router.push({ name: "apps-peeling-production-add" });
      }
    };

    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    const hasCreate = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PEELING].create
      );
    });
    const hasEdit = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PEELING].update
      );
    });
    const hasDelete = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PEELING].delete
      );
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Total",
          key: "production_data",
          sortable: false,
        },
        {
          name: "QTY",
          key: "qty",
          sortable: false,
        },
        {
          name: "Re-Qty",
          key: "reQty",
          sortable: false,
        },
        {
          name: "Status",
          key: "status",
          sortable: false,
        },
        {
          name: "Waste",
          key: "waste",
          sortable: false,
        },
        {
          name: "Remark",
          key: "remark",
          sortable: false,
        },
      ];
      if (hasEdit.value || hasDelete.value) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });

    const onClickBack = () => {
      router.back();
    };
    return {
      onClickBack,
      addProductionPeeling,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteProductionPeeling,
      getProductionPeelings,
      deleteProductionPeelingItem,
      search,
      searchItems,
      checkedProductionPeeling,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedMaterial,
      asyncUser,
      exportFewSites,
      exportPDFProductionPeelingsHanlder,
      selectAllProductionPeeling,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
    };
  },
});
